import { PropsWithChildren } from "react";
import { Head } from "@inertiajs/react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Assets/custom.css";
import { cn } from "@/lib/utils";

export default function FireLayout({
  children,
  header,
  className,
}: PropsWithChildren<{
  header?: string;
  className?: string;
}>) {
  return (
    <>
      <Head title={header} />
      <Navbar />
      <div className={cn("mx-auto px-0 sm:px-4", className)}>{children}</div>
      <Footer />
    </>
  );
}
